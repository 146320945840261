.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.breadcrumb {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.crumb {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.selected {
  background-color: aqua;
}
.selectable {
  background-color: white;
}