.questionContainer {
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 1px solid grey;
}

.question_line {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0;
}

.question_text {
  margin: 0;
}

.question_input_container {
  display: flex;
  flex-direction: column;
}

.question_input, .input_wrong_answer, .input_correct_answer {
  width: 60px;
}

.question_input {
  margin: 0 10px;
}

.student_and_actual_answer_container {
  display: flex;
  flex-direction: column;
}

input.input_wrong_answer + input.input_correct_answer, input.input_correct_answer + input.input_correct_answer, input.question_input + input.input_correct_answer {
  margin-top:10px;
}

.input_wrong_answer {
  margin: 0 10px;
  color: red;
  border-color: red;
}

.input_correct_answer {
  margin: 0 10px;
  color: green;
  border-color: green;
}

.mc_button {
  margin: 0 5px;
}

.buttonSelected {
  background-color: aqua;
}

.image {
  width: 200px;
}

.submit {
  margin: 5px 0;
}

.question_and_result {
  width: 100%;
  max-width: 36em;
}

.message {
   margin: 10px 0 0;
   text-align: center;
}
