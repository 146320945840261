
#menu {
  margin-bottom: 20px;
}
#menu button {
  margin: 10px;
}

.bargroup .namelabel {
  text-anchor: end;
  font-weight: 300;
  font-size: 0.9em;
  fill: #333;
}

.bargroup .namelabel_player {
  text-anchor: end;
  font-weight: 800;
  font-size: 0.9em;
  fill: #333;
}

.bargroup .valuelabel {
  text-anchor: end;
  fill: #fff;
  font-weight: 400;
  font-size: 0.7em;
}

.bargroup rect {
  /* Hmmm... this conflicts with react-move's leave fade! */
  /* transition: opacity 0.3s; */
}

.bargroup:hover rect {
  opacity: 0.5 !important;
}

.bargroup:hover .namelabel {
  font-weight: 400;
}
