.selector {
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.subject {
  margin: 0 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.theme {
  margin: 0 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.topic {
  margin: 0 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.selected {
  background-color: aqua;
}

.option {
  background-color: white;
}