.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}
.message {
  padding: 1em;
  font-size: 1rem;
}
.breadcrumb {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 1em;
}
.goto {
  padding: 1em;
  background-color: aqua;
}
.folder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1em;
}
.subjectupload {
  padding: 1em;
  background-color: aquamarine;
}
.questionupload {
  padding: 1em;
  background-color: cadetblue;
}
