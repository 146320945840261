.plot_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  height: 110px;
  margin: 50px;
}
.plot {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  flex: 1 0 0;
  width: 10px;
}
.label {
  order: 2;
  font-size: 8px;
  flex: 0 0 20%;
  writing-mode: vertical-rl;
}
.bar_container {
  order: 1;
  flex: 0 0 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
}
.bar {
  background-color: aqua;
  margin-left: 1px;
  margin-right: 1px;
}
